.SubmitButton{
    display: inline-block;

    min-width: 200px;
    background-color: #BF803E;
    text-align: center;
    padding-top: 14px;
    padding-bottom: 16px;
    color: white;
    margin-top: 64px;
    cursor: pointer;
    filter: brightness(1);
    font-weight: 900;
    letter-spacing: 0.15em;
    padding-left: 30px;
    padding-right: 30px;

    white-space: nowrap;

    &[data-invert='true']{
        background-color: transparent;
        color: #BF803E;
        outline: 1px solid #BF803E;

        &:hover{
            filter: brightness(0.75);
            background-color: rgba(183, 117, 54, 0.05);
        }
    }

    .SubmitButtonTitle{
        svg{
            margin-right: 8px;
            transform: scale(1.3, 1.3) translate(0px, -0.5px);
        }
    }



    &:hover{
        filter: brightness(1.25);
    }
}