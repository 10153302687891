.Nav{
    position: absolute;
    display: flex;
    height: 101px;
    //top:-10px;
    width: 100%;
    background-color: $color-background;
    color: $color-links;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    padding-top: 10px;
    padding-left: 100px;
    padding-right: 100px;
    letter-spacing: 0.1em;
    font-size: 17.5px;

    @include no-select;
    
    .Logo{
        position: relative;
        top: -2px;
        left: -3px;
        // width: 303px;
        filter: brightness(1);
        a {
            display: block;
            &:hover{
                filter: brightness(1.25);
                //opacity: 0.6;
            }
        }
        //background-color: green;
    }


    .RightLinks{
        // width: 303px;
        text-align: right;
        //background-color: green;
        .NavLink{
            text-align: right;
        }
    }
    

    .LeftLinks{
        position: relative;
        display: flex;
        flex-grow: 1;
        justify-content: space-around;
        max-width: 82%;
        .NavLink{
            text-align: center;
            flex-grow: 1;
        }
    }

    .Link{
        //background-color: green;
        padding-top: 30px;
        padding-bottom: 30px;
        cursor: pointer;
        filter: brightness(1s);
        &:hover{
            filter: brightness(1.25);
        }
    }

    .NavLink{
        position: relative;
        top: -7px;
        display: inline-block;
        //transition: color 0.5s;
        //padding-top: 30px;
        //padding-bottom: 30px;
        //background-color: red;
        
        .Menu{
            //transition: 0.5s;
            clip: rect(0px, 400px, 0px, 0px);
        }


        &[data-ios="false"]{
            &:hover{
                color: $color-links;
                .Menu{
                    clip: rect(0px, 400px, 234px, 0px);
                }
            }
        }

        &[data-ios="true"]{
            &[data-focus="true"]{
                color: $color-links;
                .Menu{
                    clip: rect(0px, 400px, 234px, 0px);
                }
            }
        }
    

        &[data-active="false"], &[data-focused="false"]{
            color: $color-links;
        }
        

        &[data-active="true"], &[data-focused="true"]{
            color: white;
        }

        &[data-exact="true"]{
            //transition: 0s;
            color: white !important;
        }

        a{
            padding-top: 14px;
            padding-bottom: 14px;
            &:hover, &:focus, &:active{
                background-color: inherit !important;
            }

            filter: brightness(1.25);
            &:hover{   
                filter: brightness(1);
                //opacity: 0.6;
            }
        }
        
        &:first-child{
            margin-left: 0px;
        }

        &:last-child{
            margin-right: 0px;
        }
    }

    .NavLinkMenu{        
        .NavLinkMenuName{
            cursor: pointer;
            padding-top: 30px;
            padding-bottom: 30px;
            filter: brightness(1);
            &:hover{
                filter: brightness(1.25);
                //opacity: 0.6;
            }
        }
        &:focus{
            outline: none;
            outline-color: none !important;
            outline-style: none !important;
            outline-width: 0 !important;
            box-shadow: none !important;
            border:none !important;
            background-color: inherit !important;
        }
    }

    .Menu{
        position: absolute;
        //top: 61px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 6px;
        background-color: $color-background;

        
        .NavLink{
            display: block;
            width: 100%;
            padding-top: 0px;
            padding-bottom: 0px;

            .Link{
                padding-top: 14px;
                padding-bottom: 14px;
            }

            &:first-child{
                padding-top: 4px;
            }
        }
    }
}