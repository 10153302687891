.Check{
    display:flex;
    justify-content: space-between;
    align-items:center;
    width: 400px;
    cursor: pointer;
    padding-top: 30px;
    font-size: 26px;
    font-weight: 300;
    color: #767676;

    .check-icon{
        color: #BF803E;
    }
}