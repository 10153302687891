:root {
    --scale: 1;
    --offset-x: 0px;
    --offset-y: 0px;
}

$app-width: 1920px;
$app-height: 1080px;
$header-height: 100px;

$color-background: #000000;
$color-dark-background: #000000;
$color-links: #C68B47;
$color-hover: #ffffff;
