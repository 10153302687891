.Field{
    width: 100%;

    &[data-has-errors="true"]{
        .Input{
            border-bottom-color: red;
        }
    }
    
    .Input{
        border-radius: 0px;
        font-weight: 100;
        width: 100%;
        background-color: transparent !important;
        //border: none !important;
        border: 2px solid #C68B47;
        margin-top: 18px;
        color: white;
        font-size: 22px;
        font-family: inherit !important;
        padding: 14px;
        text-align: center;

        &:focus{
            &::placeholder{
                visibility: hidden;
            }
        }

        &::placeholder{
            color: white;
            opacity: 0.6;
        }
        
    }
}