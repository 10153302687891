.SidePanelButton{
    color: black;
    cursor: pointer;
    border: 1px solid black;
    float: left;
    padding: 8px;
    padding-left: 13px;
    padding-right: 21px;
    width: 160px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    margin-right: 20px;

    .SidePanelButtonIcon{
        opacity: 0.7;
    }
}