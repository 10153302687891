.Form{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    form{
        width: 27%;
        height: 50%;
        text-align: center;
    }
}