.RenderIndicator{
    transform: scale(0.55, 0.55) translate(-10px, 0px);
    cursor: pointer;
}

.SaleIndicator{
    $w: 56px;
    $h: 56px;

    width: $w !important;
    height: $h !important;
    max-width: $w;
    max-height: $h;
    min-height: $h;
    min-width: $w;
    border-radius: 50%;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    vertical-align: center;
    padding-top: 15px;
    color: white;


    position: relative;
    left: -4px;

    &[data-sold='true']{
        background-color: #9D402E;
    }

    &[data-res='true']{
        background-color: #737373;
    }

    div{
        position: relative;
        top: 1px;
        left: 0.5px;
    }
}


.SaleIndicatorJustText{
    position: relative;
    left: -4px;
    top: -4px;
    color: white;
    font-weight: 900;
    width: 100px;
    text-align: center;
    letter-spacing: 0.0em;

    .SaleIndicatorJustTextText{
        &[data-favourited='true']{
            color: black !important;
        }
    }
    
    .SaleIndicatorHeart{
        text-align: center;
        svg{
            position: absolute;
            transform: translate(-50%, -9px);
            z-index: -1;
        }
    }
}