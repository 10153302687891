.Selection{
    $width: 160px;
    padding-right:20px;
    float: left;

    color: #000000;
    position: relative;
    min-height: 100px;
    cursor: pointer;
    
    /*
    &:hover{
        opacity: 0.85;
    };
    */

    .SelectionLabel{
        letter-spacing: 0.05em;
        font-size: 16px;
    }

    .SelectionItem{
        font-size: 18px;
    }
  

    .SelectionIcon{
        font-size: 40px;
        position: absolute;
        left: 110px;
        top: -8px;
        pointer-events: none;
        
        //display: none; // Here

        .SelectionIconClose{
            transform: translate(0px, 2px) scale(0.45) rotate(-180deg);
        }

        .SelectionIconOpen{
            transform: translate(0px, 32px) scale(0.45);
        }

        background-color: green;

        div{
           position: absolute;
           left: 0px;
           top: 0px;
           //background-color: red;
           
        }
    }

    .SelectionEmpty{
        visibility: hidden;
        width: $width;
        height: 8px;
    }

    .SelectionSelect, .SelectionOptions{
        border: 1px solid black;
        position: absolute;
        width: $width;
    }

    .SelectionSelect{
        padding: 8px;
        padding-left: 13px;
        padding-right: 13px;
    }

    &[data-expanded='true']{
        .SelectionSelect{
            display: none;
        }

        .SelectionIconOpen{
            display: none;
        }
    }

    &[data-expanded='false']{
        .SelectionOptions{
            display: none;
        }
        .SelectionIconClose{
            display: none;
        }
    }


    .SelectionOptions{
        background-color: #EFEBE0;
        z-index: 100;
        
        .SelectionOption{
            padding: 10px;
            padding-left: 13px;
            padding-right: 13px;

            &:first-child{
                padding-top:20px;
            }

            &:last-child{
                padding-bottom:20px;
            }
        }
    }

   
}