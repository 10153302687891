.Views {
    position: relative;
    top: $header-height;
    height: 0;
    padding-top: 51.04%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}