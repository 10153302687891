.Zoom{
    .ZoomUI{
        position: absolute;
        bottom: -4px;
        right: 160px;
        display:flex;

        .ZoomIn, .ZoomOut{
            transform: scale(0.5, 0.5);
            cursor: pointer;
        }

        .ZoomIn{
            position: relative;
            left: 20px;
        }
    }
}