.Favourites{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 999999;

    transition: 0.5s opacity;

    &[data-showing='true']{
        opacity: 1;
       
    }

    &[data-showing='false']{
        opacity: 0;
        pointer-events: none;
        //display:none;
    }

    .FavouritesClose{
        cursor: pointer;
        position: absolute;
        top: 22px;
        right: 0;
        &:hover{
            opacity: 0.6;
        }
    }

    .FavouritesMargin{
        position:relative;
        top: 100px;
        height: calc(100% - 100px);
        margin-left: 80px;
        margin-right: 80px;
        display: flex;
        flex-direction: column;
        
   
        .FavouritesTop{
            display: flex;
            align-items: baseline;
            
            h1{
                font-size: 40px;
                margin-right: 60px;
            }
            .ShowHideEmail, .ClearFavourites{
                cursor: pointer;
                font-size: 26px;
                color: rgba(0, 0, 0, 0.6);
                
                &:hover{
                    opacity: 0.85;
                }
                
                .FavouriteEmailIcon{
                    margin-right: 10px;
                    opacity: 0.9;
                }
            }

            .ClearFavourites{
                position:relative;
                left: 800px;
            }
        }


        .FavouritesThumbs{
            margin-top: 40px;
            overflow-y: scroll;
            overflow-x: hidden;
            flex:1;

            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */

            &::-webkit-scrollbar {
                width: 0px;
                background: transparent; /* make scrollbar transparent */
            }

            .FavouritesThumbTitle{
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .FavouritesThumbTitleClose{
                    cursor: pointer;

                    img{
                        width: 20px;
                        height: auto;
                        position: relative;
                        right: 0px;
                        top: 6spx;
                    }
                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
            
            .FavouritesThumb{
                float: left;
                margin-right: 44px;
                margin-bottom: 50px;
                &:nth-child(4n){
                    margin-right: 0px;
                }
                .FavouritesThumbTitle{
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }
                .FavouritesThumbImage{
                    width:400px;
                    height: auto;
                    cursor: pointer;

                    &:hover{
                        filter: brightness(0.95);
                    }
                }
            }
        }
        
        .FavouritesForm{
            overflow: hidden;
            transition: height 0.5s;

            > div{
                clear:both;
            }

            &[data-collapsed='true']{
                height:0px;    
            }

            &[data-collapsed='false']{
                height:430px;
            }

            .Input{
                border-radius: 0px;
                border-top: none;
                border-left: none;
                border-right: none;
                color: black;
                width: 400px;
                text-align: left;
                padding-left: 0px;
                padding-right: 0px;
                margin-bottom: 0px;
                margin-top: 20px;
                font-size: 26px;
                float: left;
                margin-right: 60px;

                &::placeholder{
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }

        
    }
}