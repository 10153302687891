.FloorPlan{
    position: relative;
    top: $header-height;
    width: 1470px;
    height: 980px;

    .FloorPlanRotateLeft, .FloorPlanRotateRight{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) scale(0.6);
        cursor: pointer;

        :hover{
            filter: brightness(1.1);
        }

        :active{
            filter: brightness(1.3);
        }
    }

    .FloorPlanRotateLeft{
        left: 30px;
    }

    .FloorPlanRotateRight{
        right: 30px;
    }

    .FloorPlanCoords{
        width: 100%;
        height: $app-height - $header-height;
        position: absolute;

        .FloorPlanCoord{
            display:flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: absolute;
            width: 50px;
            height: 50px;
            cursor: pointer;
            color: $color-background;
            font-size: 18px;

            &[data-no-clicks='true']{
                pointer-events: none;
            }

            filter: brightness(1);

            /*
            .FloorPlanThumb{
                object-fit: cover;
                object-position: right top;
                width: 500px;
                height: 500px;
                transform: translate(50%, 50%);
                visibility: hidden;
                pointer-events: none;
            }
            */

            &:hover{
                .FloorPlanIndicator{
                    filter: brightness(1.07);
                }
                .SaleIndicator{
                    filter: brightness(1.7);
                }
                .FloorPlanThumb{
                    visibility: visible;
                }
            }
        }
    }
    .FloorplanThumb{
        position: absolute;
        transform: translate(-150px, 55px);
        filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.7));
        cursor: pointer;
        z-index: 1;
        &.flip-y{
            transform: translate(-150px, calc(-100% - 30px));
            .FloorplanThumbStrip{
                margin-top: -8px;
                .FloorplanThumbIcon{
                    transform: scale(0.7, 0.7) translate(0, 2px);
                }
            }
        }

        .FloorplanThumbStrip{
            width: 100%;
            height: 40px;
            background-color: #EDE8DC;
            position:relative;
            bottom: -2px;
            text-align: center;

            .FloorplanThumbIcon{
                color: black;
                opacity: 0.5;
                transform: scale(0.7, 0.7) translate(0, 8px);
            }
        }
        img{
            width: 340px;
            height: auto;
            border: 3px solid #EDE8DC;
        }
    }
}